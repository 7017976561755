import React, {Component} from 'react';
import './GoogleForm.css'

class GoogleForm extends Component {

    componentDidMount() {

    }

    render() {
        return (

    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe34eNTlnyFuDFKWZmPg9N1gDSvTtOj4B38T8n1kMoSEY4igQ/viewform"
        width='100%'
        height='100%'>

    </iframe>
        );
    }
}


export default GoogleForm;