import logo from './logo.svg';
import './App.css';
import Redoc from './components/Redoc';
import MarkdownRenderer from './components/MarkdownRenderer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import GoogleForm from './components/GoogleForm';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <MarkdownRenderer path={'/README.md'} />
          </Route>
          <Route path="/documentation" exact>
            <Redoc specUrl={'endpoints.yaml'} />
          </Route>
          <Route path="/internal" exact>
            <Redoc specUrl={'endpoints.internal.yaml'} />
          </Route>
          <Route path="/requestaccess" exact>
            <GoogleForm />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
