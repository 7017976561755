import React, { Component, PropTypes } from 'react';
import fs from 'fs';
import toc from 'remark-toc'
// import toc from '@jsdevtools/rehype-toc'
// import toc from 'markdown-toc'
import slug from 'remark-slug'
import 'github-markdown-css'
import './MarkdownRenderer.css'
import ReactMarkdown from 'react-markdown';
import Markdown from 'react-markdown';
import gfm from 'remark-gfm'
var link = require('rehype-autolink-headings')




class MarkdownHeading extends Component {

    constructor(props) {
        super(props)
    }

    flatten = (text, child) => {
        return typeof child === 'string'
            ? text + child
            : React.Children.toArray(child.props.children).reduce(this.flatten, text)
    }

    render() {
        var children = React.Children.toArray(this.props.children)
        var text = children.reduce(this.flatten, '')
        var slug = text.toLowerCase().replace(/\W/g, '-')
        return React.createElement('h' + this.props.level, { id: slug }, this.props.children)
    }
}

class MarkdownRenderer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            markdown: ""
        }

        if (props.path) this.getFromFile(props.path)
    }

    getFromFile = (URI) => {
        fetch(URI)
            .then((r) => r.text())
            .then(md => {
                this.setState({ markdown: md })
            })
    }

    render() {
        return (
            <div>
                <div className='markdown-body'>
                    <Markdown
                        source={this.state.markdown}
                        remarkPlugins={[toc, slug, gfm]}
                        // rehypePlugins={[link]}
                        children={this.state.markdown}
                        skipHtml={true}
                        sourcePos={false}
                        includeNodeIndex={true}
                        includeElementIndex={true}
                        // components={{heading: MarkdownHeading}}

                    />
                </div>
            </div>
            // <ReactMarkdown remarkPlugins={[gfm]} children={this.state.markdown} />
        );
    }
}


export default MarkdownRenderer;